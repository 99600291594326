import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation, useNavigate } from "react-router-dom";
import { missingPopup } from "../../helpers/dataHelper/missingData";
import { toastify } from "../../helpers/toast/toastify";
// import { api } from "../../helpers/apiHelper/requestHelper";
import Header from "../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import useApi from "../../helpers/apiHelper/requestHelper";
import { Form } from "react-bootstrap";
import validator from "validator";
import Button from "../../components/button/Button";
// import binanceBlack from "../../../assets/images/Binance_Blacks.svg";
import binanceBlack from "../../assets/images/Binance_Blacks.svg";
import axios from "axios";
import {
  setLoading,
  setResponseLoading,
  setSettingUpService,
  setShowConnectionSuccessModal,
  setShowConnectionFailModal,
  setConnectionFailMessage,
  setBinanceConnecting,
} from "../../redux/loading/loadingSlice";
import LoadingModal from "../../components/loader/loadingModal";
import { storeUser, updateUser } from "../../redux/users/usersSlice";
import { useAnimate, usePresence, stagger } from "framer-motion";
import InputComponent from "../../components/input/InputComponent";
import Footer from "../../components/footer/Footer";
import { setCurrentTab } from "../../redux/links/linksSlice";
import { setShowSidebar } from "../../redux/sidebar/sidebarSlice";
import {
  socailSignUp,
  socialSignUpSlice,
} from "../../redux/socialSignup/socialSignUp";

const handleClick = () => {
  window.scrollTo(0, 0);
};

export default function SignupLeader() {
  const api = useApi();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.user);
  const isLoading = useSelector((state) => state?.loader?.isLoading);
  const responseLoading = useSelector(
    (state) => state?.loader?.awaitingResponse
  );
  const [data, setData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    role: "leader",
    termsConditions: false,
    acceptMarketing: false,
  });
  const [bybitConnection, setBybitConnection] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");
  const [focusedInput, setFocusedInput] = useState(null);
  const [verify, setVerify] = useState(false);
  const [isPresent, safeToRemove] = usePresence();
  const [scope, animate] = useAnimate();
  const [otp, setOtp] = useState("");
  const [cypressOtp, setCypressOtp] = useState("");
  const [verifyData, setVerifyData] = useState({});
  const [codeIncorrect, setCodeIncorrect] = useState(false);
  const [showTermsMessage, setShowTermsMessage] = useState(false);
  const numberRegex = new RegExp(`^[0-9]*$`);
  const socialSignUp = useSelector((state) => state?.socialSignUp?.value);

  const handleFocus = (inputName) => {
    setFocusedInput(inputName);
  };

  const handleBlur = () => {
    setFocusedInput(null);
  };

  const handlePasswordChange = (value) => {
    const newPassword = value;
    if (!newPassword) {
      setValidationMessage("");
    } else if (!/[A-Z]/.test(newPassword)) {
      setValidationMessage(
        "Password must contain at least one uppercase letter"
      );
    } else if (!validator.isLength(newPassword, { min: 8 })) {
      setValidationMessage("Password must be at least 8 characters long");
    } else if (!/[0-9]/.test(newPassword)) {
      setValidationMessage("Password must contain at least one digit");
    } else if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(newPassword)) {
      setValidationMessage(
        "Password must contain at least one special character"
      );
    } else {
      setValidationMessage(""); // Clear validation message if all rules are satisfied
    }

    setData((prevState) => ({
      ...prevState,
      password: newPassword,
    }));
  };

  useEffect(() => {
    localStorage.removeItem("AllowSocialLogin");
    const urlParams = new URLSearchParams(window.location.search);

    const state = urlParams.get("state");
    const code = urlParams.get("code");
    // dispatch(setLoading(false));
    if (!(code && state)) {
      dispatch(setLoading(false));
      dispatch(setResponseLoading(false));
    }
  }, []);

  const loginWithByBit = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    if (code) {
      dispatch(setLoading(true));
      dispatch(setResponseLoading(true));
      if (user.role === "leader")
        navigate("/leader/copyTrade", {
          state: { tab: "connection" },
        });
      else {
        navigate("/member/copyTrade", {
          state: { tab: "connection" },
          replace: true,
        });
      }
      // dispatch(setLoading(true));
      dispatch(setResponseLoading(true));
      try {
        const response = await api("post", "users/loginWithByBit", {
          exchange: "ByBit",
          code: code,
        });
        if (response?.status) {
          setBybitConnection(true);
          // toastify("ByBit connected successfully!", "success");
          dispatch(updateUser(response?.data));
          dispatch(setShowConnectionSuccessModal(true));
          dispatch(setResponseLoading(false));
          if (user?.firstVisit) {
            setTimeout(async () => {
              if (user?.role === "member") {
                dispatch(setCurrentTab("subscribe"));
                await api("post", "users/update", {
                  onboardingStep: "subscribe",
                });
              }
              if (user?.role === "leader") {
                dispatch(setCurrentTab("payment"));
                await api("post", "users/update", {
                  onboardingStep: "payment",
                });
              }
            }, 2000);
          }

          // dispatch(setLoading(false));
        } else {
          dispatch(setLoading(false));
          dispatch(setResponseLoading(false));
          dispatch(setConnectionFailMessage(response?.message));
          dispatch(setShowConnectionFailModal(true));
        }
      } catch (error) {
        dispatch(setLoading(false));
        dispatch(setResponseLoading(false));
        console.error("Error while calling loginWithByBit:", error);
      }
    }
  };

  const loginWithByBitSuccess = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    if (user) {
      await loginWithByBit();
      if (user.role === "leader" && bybitConnection) {
        navigate("/leader/copyTrade", {
          state: { tab: "connection" },
        });
      } else if (user.role === "member" && bybitConnection)
        navigate("/member/copyTrade", {
          state: { tab: "connection" },
          replace: true,
        });
    }
  };

  useEffect(() => {
    loginWithByBitSuccess();
  }, [window.location, user]);

  const handleSignup = async () => {
    dispatch(setLoading(true));
    if (validationMessage !== "") {
      toastify("Please enter a valid password");
      dispatch(setLoading(false));
      return;
    }
    if (!data?.name?.trim()) {
      dispatch(setLoading(false));
      missingPopup("Business Name");
    } else if (!data?.email) {
      dispatch(setLoading(false));
      missingPopup("Email");
    } else if (!/\S+@\S+\.\S+/.test(data?.email)) {
      dispatch(setLoading(false));
      toastify("Please enter a valid email address.");
    } else if (!data?.password) {
      dispatch(setLoading(false));
      missingPopup("Password");
    } else if (!data?.confirmPassword) {
      dispatch(setLoading(false));
      missingPopup("Confirm Password");
    } else if (data?.password !== data?.confirmPassword) {
      dispatch(setLoading(false));
      toastify("Passwords do not match", "error");
    } else if (!data.termsConditions) {
      dispatch(setLoading(false));
      return setShowTermsMessage(true);
    } else {
      const response = await api("post", "auth/signup", data);
      setCypressOtp(response?.data?.otp);
      if (response?.status) {
        dispatch(setLoading(false));
        localStorage.removeItem("tourClose");
        setVerify(true);
        setVerifyData({ email: data?.email });
      } else {
        dispatch(setLoading(false));
        toastify(response?.message);
      }
    }
  };

  const handleResendCode = async () => {
    const response = await api("post", "auth/login", data);

    if (response?.status) {
      toastify(response?.message, "success");
    }
  };

  const handleVerify = async () => {
    if (!otp || otp.length < 6) {
      setCodeIncorrect(true);
    } else {
      dispatch(setSettingUpService(true));

      // Add setTimeout for 2 seconds
      const response = await api("post", "auth/verifyEmailOtp", {
        email: verifyData?.email,
        otp,
      });

      if (response?.status) {
        setTimeout(() => {
          dispatch(setSettingUpService(false));
        }, 4000);

        // toastify("Email verified successfully.", "success");
        dispatch(storeUser(response?.data));
        localStorage.setItem("token", response?.data?.token);
        await api("post", "users/update", {
          security: { otp: false },
        });
        if (response?.data?.user?.role === "member") {
          const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");

          if (lastVisitedRoute?.startsWith("/member/") && !user?.firstVisit) {
            localStorage.removeItem("lastVisitedRoute");
            navigate(lastVisitedRoute);
          } else {
            navigate("/member/dashboard", { replace: true });
          }
        } else {
          const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");

          if (lastVisitedRoute && lastVisitedRoute?.includes("leader")) {
            localStorage.removeItem("lastVisitedRoute");
            // navigate(lastVisitedRoute);
            navigate("/leader/copyTrade", { replace: true });
            dispatch(setShowSidebar(false));
            dispatch(setCurrentTab("subscription"));
          } else {
            navigate("/leader/copyTrade", { replace: true });
            dispatch(setShowSidebar(false));
            dispatch(setCurrentTab("subscription"));
          }
        }
      } else {
        setTimeout(() => {
          dispatch(setSettingUpService(false));
        }, 1000);
        toastify(response?.message);
      }
    }
  };

  useEffect(() => {
    const enterAnimation = async () => {
      await animate(
        scope.current,
        {
          x: verify ? [300, 0] : [-300, 0], // Updated for verify state
          // opacity: [0, 1],
          delay: stagger(0.1, { ease: "easeOut" }),
        },
        { duration: 0.2 }
      );
    };

    const exitAnimation = async () => {
      await animate(
        scope.current,
        {
          x: verify ? [0, -300] : [0, 300], // Updated for verify state
          // opacity: [1, 0],
          delay: stagger(0.1, { ease: "easeOut" }),
        },
        { duration: 0.2 }
      );
      safeToRemove();
    };

    if (isPresent) {
      enterAnimation();
    } else {
      exitAnimation();
    }
  }, [scope, isPresent, verify]);

  const liveUrl = true;

  useEffect(() => {
    if (otp?.length === 6) {
      handleVerify();
    }
  }, [otp]);

  const location = useLocation();

  useEffect(() => {
    const handleOAuthResponse = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");
      const exchangeClick = localStorage.getItem("ExchangeClick");
      if (code) {
        dispatch(setLoading(true));
        setTimeout(() => {
          dispatch(setResponseLoading(true));
          dispatch(setLoading(true));
        }, 0);
        try {
          const response = await api("post", "social/socialLogin", {
            exchange: exchangeClick,
            code: code,
            community: socialSignUp?.community,
          });
          setTimeout(() => {
            //we set loader below but they didn't work
            dispatch(setLoading(false));
            dispatch(setResponseLoading(false));
          }, 0);
          if (response?.status) {
            console.log("response", response);

            localStorage.setItem("token", response?.data?.token);
            dispatch(storeUser(response?.data));
            const role = response?.data.user.role;
            if (!response?.data?.user?.socialLogin?.emailVerified) {
              setTimeout(() => {
                dispatch(setLoading(false));
                dispatch(setResponseLoading(false));
              }, 0);
              dispatch(setShowSidebar(false));
              navigate(`/${role}/emailGetter`, { replace: true });
            } else {
              navigate(`/${role}/dashboard`, { replace: true });
            }
          }
          if (!response?.status) {
            localStorage.removeItem("AllowSocialLogin");
            toastify(response?.message);
          }
        } catch (error) {
          console.error("Error during API call", error);
        }
      }
    };

    handleOAuthResponse();
  }, [location.search]);

  return (
    <>
      {!user && (
        <div className="wrapper common_style">
          <Header />
          {isLoading && <LoadingModal />}

          <section className="sign_section d-flex align-items-center">
            {verify ? (
              <div className="login_card account_card">
                <div className="container">
                  <div className="row justify-content-center align-items-center">
                    <div className="col-sm-12 px-0">
                      <div className="d-flex justify-content-between flex-column">
                        <div>
                          <h2 className="fs20 li_h30 text_GB mb-9">
                            Check your email{" "}
                          </h2>
                          <h5 className="text-center fs14 mb-26 regular">
                            Use the code sent to your email on it
                          </h5>

                          {/* <p className="mb-3 ps-2">
                                 Please check your spam folder too
                             </p> */}
                          <Form.Group ref={scope} controlId="formOtp">
                            {/* <Form.Label>Enter the code here</Form.Label> */}
                            <InputComponent
                              type="text" // or "password" based on your requirement
                              label="Paste code here"
                              focusedInput={otp ? "focused" : ""}
                              handleFocus={handleFocus}
                              value={otp}
                              maxLength={6}
                              onChange={(inputValue) => {
                                if (
                                  numberRegex.test(inputValue) ||
                                  inputValue === ""
                                ) {
                                  setOtp(inputValue);
                                }
                              }}
                            />
                            {codeIncorrect && (
                              <p className="text-danger fs15">Code incorrect</p>
                            )}
                          </Form.Group>
                        </div>
                        <div className="">
                          <div className="mt-19">
                            <button
                              className="newCreateButton"
                              onClick={handleVerify}
                            >
                              Verify Email
                            </button>
                          </div>
                          <div className="mt-12 text-center">
                            <Link
                              className="newSignLink"
                              style={{ cursor: "pointer" }}
                              onClick={handleResendCode}
                            >
                              Resend code
                            </Link>
                          </div>
                          {/* <button
               className="btn btn-success radius min_h57 w-100 fs20"
               onClick={() => handleVerify()}
             >
               Verify
             </button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="login_card account_card">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-sm-12 px-0">
                      <div className="d-flex flex-column align-items-center justify-content-center">
                        <h2 className="fs20 text_GB mb-9">Create an account</h2>
                        <h6 className="fs14 mb-26 text-center">
                          Submit email and create a strong password
                        </h6>
                      </div>
                      <div ref={scope}> </div>
                      <div className="pb-13">
                        <InputComponent
                          type="text"
                          label="Business name (customers see this)"
                          focusedInput={focusedInput}
                          handleFocus={handleFocus}
                          handleBlur={handleBlur}
                          value={data.name}
                          onChange={(value) => {
                            // Remove special characters using a regular expression
                            const sanitizedValue = value.replace(
                              /[^a-zA-Z0-9 ]/g,
                              ""
                            );
                            setData((prevState) => ({
                              ...prevState,
                              name: sanitizedValue,
                            }));
                          }}
                        />
                      </div>
                      <div className="pb-13">
                        <InputComponent
                          type="email"
                          label="Email"
                          focusedInput={focusedInput}
                          handleFocus={handleFocus}
                          handleBlur={handleBlur}
                          value={data.email}
                          onChange={(value) =>
                            setData((prevState) => ({
                              ...prevState,
                              email: value,
                            }))
                          }
                        />
                      </div>
                      <div className="pb-13">
                        <InputComponent
                          type="password"
                          label="Password"
                          focusedInput={focusedInput}
                          handleFocus={handleFocus}
                          handleBlur={handleBlur}
                          value={data?.password}
                          onChange={(val) => handlePasswordChange(val)}
                        />
                        {validationMessage && (
                          <div className="text-danger">{validationMessage}</div>
                        )}
                      </div>
                      <div className="pb-0">
                        <InputComponent
                          type="password"
                          label="Verify Password"
                          focusedInput={focusedInput}
                          handleFocus={handleFocus}
                          handleBlur={handleBlur}
                          value={data?.confirmPassword}
                          onChange={(value) =>
                            setData((prevState) => ({
                              ...prevState,
                              confirmPassword: value,
                            }))
                          }
                        />
                      </div>

                      <div className="pt-14">
                        <div className="d-flex">
                          <div className="me-2">
                            <input
                              id="term1Checkbox"
                              type="checkbox"
                              checked={data?.termsConditions}
                              onChange={(e) =>
                                setData((prevState) => ({
                                  ...prevState,
                                  termsConditions: e.target.checked,
                                }))
                              }
                            />
                          </div>
                          <div className="fs12 text_muted5 mt-1">
                            <p>
                              I accept the Terms & Conditions and have read the
                              Privacy Policy and allow copyyy.it to contact me
                              by email regarding this service.
                            </p>
                            {showTermsMessage && (
                              <p className="text-danger fs16 mt-3">
                                Please accept so we can send you emails
                                regarding this service.{" "}
                              </p>
                            )}
                          </div>
                        </div>

                        {/* <div className="d-flex py-2">
                                        <div className="me-2">
                                            <input
                                                id="cryptoCheckbox"
                                                type="checkbox"
                                                checked={data?.acceptMarketing}
                                                onChange={(e) =>
                                                    setData((prevState) => ({
                                                        ...prevState,
                                                        acceptMarketing:
                                                            e.target.checked,
                                                    }))
                                                }
                                            />
                                        </div>
                                        <div className="fs12 text_muted5 mt-1">
                                            I’m ok with getting occasional
                                            marketing emails with promotional
                                            offers related to my trading.
                                        </div>
                                    </div> */}
                        <div className="mt-25">
                          <button
                            className="newCreateButton"
                            // disabled={
                            //     !data?.termsConditions
                            // }
                            // onClick={handleSignup}
                            onClick={() => {
                              handleClick();
                              handleSignup();
                            }}
                          >
                            Sign up
                          </button>

                          <div className="mt-12">
                            <Link className="newSignLink " to="/login">
                              Sign in instead
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </section>

          <Footer cypressOtp={cypressOtp} />

          {/* <footer className="site_footer">
                <div className="container">
                    <div className="row">
                        <div className="login_footer">
                            <div className="col-12">
                                <div className="d-flex justify-content-between">
                                    <p>
                                        Powered by{" "}
                                        <a className="text-dark">copyyy.it</a>
                                    </p>
                                    <ul>
                                        <li>
                                            <a href="">Help</a>
                                        </li>
                                        <li>
                                            <a href="">Privacy</a>
                                        </li>
                                        <li>
                                            <a href="">Terms</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer> */}
        </div>
      )}
    </>
  );
}
